<template>
    <!-- 优秀员工详情页 -->
    <div class="spDetail">
        <div class="topBg">
            <img src="https://www.hunuo.com/images/bobanner.jpg" alt="" />
        </div>
        <!-- 职位要求 -->
        <div class="ppbBox">
            <div class="ava">
                <img :src="spdData.image" />
            </div>
            <div class="name">{{ spdData.name }}</div>
            <div class="editor-p" v-html="spdData.details"></div>
        </div>
        <div class="theHonor" v-if="spdData.children && spdData.children.length">
            <div class="thTitle">荣誉奖:</div>
            <!-- 轮播展示 -->
            <div class="swiper-container" id="SpDetail">
                <div class="swiper-wrapper">
                    <div class="swiper-slide sw-sl" v-for="(el, index) in spdData.children" :key="el.id">
                        <div :data-index="index"></div>
                        <img :src="el.image" :data-src="el.image" />
                    </div>
                </div>
            </div>
            <div class="prev" @click="prev"></div>
            <div class="next" @click="next"></div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
export default {
    name: "SpDetail",
    data() {
        return {
            spdData: {},
            viewer: {},
            swiper: {},
        };
    },
    created() {
        if (typeof this.$route.query.emSpd != "object") {
            this.$router.replace("/staffPage");
            return;
        }
        this.spdData = this.$route.query.emSpd;
    },
    mounted() {
        let _this = this;
        _this.viewer = new Viewer(document.getElementById("SpDetail"), {
            title: false,
        });
        this.$nextTick(() => {
            _this.swiper = new Swiper("#SpDetail", {
                slidesPerView: 5,
                spaceBetween: 10,
                centeredSlides: true,
                loop: _this.spdData.children.length <= 5 ? false : true,
                autoplay: true,
                on: {
                    click(e) {
                        // 无限轮播复制的li无法复制事件，所以写在这
                        _this.viewer.show();
                        _this.viewer.view(e.target.dataset.index);
                    },
                },
            });
        });
    },
    methods: {
        prev() {
            this.swiper.slidePrev();
        },
        next() {
            this.swiper.slideNext();
        },
    },
};
</script>

<style lang="less" scoped>
.spDetail {
    width: 100%;
    min-height: 1000px;
    // margin-top: 98px;
    text-align: left;
    .topBg {
        width: 100%;
        img {
            width: 100%;
        }
    }
    .ppbBox {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        .name {
            font-size: 40px;
            // height: 50px;
            line-height: 50px;
            padding: 20px;
        }

        .ava {
            box-shadow: 1px 1px 10px 1px #dcdcdc;
            border-radius: 20px;
            height: 280px;
            width: 260px;
            position: absolute;
            top: 10px;
            right: 10px;
            // overflow: hidden;
            padding: 20px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .theHonor {
        max-width: 1423px;
        // height: 500px;
        margin: 0 auto;
        position: relative;
        .thTitle {
            width: 1280px;
            height: 30px;
            margin: 0 auto;
            line-height: 30px;
            font-size: 20px;
            color: #7781f1;
            font-weight: 900;
        }
        .swiper-container {
            width: 90%;
            margin: 0 auto;
            padding-bottom: 20px;
            /deep/.swiper-slide-active,
            .swiper-slide-duplicate-active {
                transform: scale(1) !important;
            }
            .sw-sl {
                // height: 350px;
                // background-color: red;
                transition: 300ms;
                transform: scale(0.8);
                overflow: hidden;
                border-radius: 10px;
                position: relative;
                cursor: pointer;
                & > div {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    // display: inline-block;
                }
                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    // max-width: 250px;
                    // max-height: 250px;
                }
            }
        }
        .prev {
            position: absolute;
            top: 50%;
            left: 20px;
            width: 40px;
            height: 70px;
            background: url("https://v.vu818.com/img/vu818.355fb90e.png") no-repeat;
            background-position: -226px -893px;
            display: none;
        }
        .next {
            position: absolute;
            top: 50%;
            right: 20px;
            width: 40px;
            height: 70px;
            background: url("https://v.vu818.com/img/vu818.355fb90e.png") no-repeat;
            background-position: -267px -893px;
            display: none;
        }
    }
}
</style>
